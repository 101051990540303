export default {
  chart1: {
    isDefaultMode: true,
    loading: false,
    chartData: null,
    rawData: [],
    chartOptions: {
      height: 300,
      //  curveType: 'function',
      chartArea: {
        width: '80%',
        height: '80%'
      },
      title: '',
      subtitle: '',
      series: {
        0: {
          targetAxisIndex: 0
        }
        /*
                          1: {
                              targetAxisIndex: 1
                          },
                          */
      },
      vAxis: {
        minValue: 0
      },
      hAxis: {
        //  format: 'MMM, YY',
      }
    }
  },
  chart2: {
    isDefaultMode: true,
    loading: false,
    chartData: null,
    rawData: [],
    chartOptions: {
      height: 300,
      chartArea: {
        width: '80%',
        height: '80%'
        // left: "70"
      },
      title: '',
      subtitle: '',
      isStacked: 'percent',
      pointSize: 3,
      hAxis: {
        //  format: 'MMM, YY',
      }
    }
  },
  chart3: {
    isDefaultMode: true,
    loading: false,
    chartData: null,
    rawData: [],
    chartOptions: {
      height: 300,
      chartArea: {
        width: '80%',
        height: '80%'
        //  left: "70"
      },
      title: '',
      subtitle: '',
      isStacked: 'percent',
      pointSize: 3,
      hAxis: {
        //   format: 'MMM, YY',
      }
    }
  },
  chart4: {
    isDefaultMode: true,
    loading: false,
    chartData: null,
    chartOptions: {
      height: 350,
      chartArea: {
        width: '80%',
        height: '75%'
      },
      title: '',
      legend: {
        position: 'bottom'
      },
      vAxes: {
        1: {
          title: 'Total Sales',
          minValue: 0
        },
        0: {
          title: 'Touchpoints',
          minValue: 0
        }
      },
      hAxis: {
        // format: 'MMM, YY',
      },
      seriesType: 'bars',
      series: {
        0: {
          type: 'line',
          targetAxisIndex: 1,
          color: 'gray',
          enableInteractivity: false
        },
        1: {
          targetAxisIndex: 0,
          color: 'green'
        },
        2: {
          targetAxisIndex: 0,
          color: '#3366cc'
        },
        3: {
          targetAxisIndex: 0,
          color: 'orange'
        },
        4: {
          targetAxisIndex: 0,
          color: 'orange'
        }
      }
    }
  },
  chart5: {
    isDefaultMode: true,
    loading: false,
    chartData: null,
    chartOptions: {
      height: 350,
      chartArea: {
        width: '80%',
        height: '70%'
      },
      title: '',

      subtitle: '',
      legend: {
        position: 'none'
      },
      isStacked: true,
      series: {
        0: {
          enableInteractivity: false,
          dataOpacity: 0.0
        },
        1: {}
      },
      vAxis: {
        minValue: 0
      }
    }
  }
}
