export default {
  callout1: {
    value: "",
    header: "",
    subheader: "",
    footer: "",
    postfix: "",
    hideSign: false,
    bgColorClass: ""
  },
  callout2: {
    value: "",
    header: "",
    subheader: "",
    footer: ""
  },
  callout3: {
    value: "",
    header: "",
    subheader: "",
    footer: ""
  },
  periodPrev: ""
};
