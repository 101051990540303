export default {
  table1: {
    dataSet: [],
    onRowClick: {},
    loading: false,

    options: {
      title: 'Top 5 On-premise Accounts',
      showFullLink: true,
      subtitle: '',
      formatColumns: ['Total sales'],
      totalColumns: [
        {
          name: 'Total sales',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  table2: {
    dataSet: [],
    onRowClick: {},
    loading: false,
    options: {
      title: 'Top 5 Off-premise Accounts',
      showFullLink: true,
      subtitle: '',
      formatColumns: ['Total Sales'],
      totalColumns: [
        {
          name: 'Total sales',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  table3: {
    dataSet: [],
    onRowClick: {},
    loading: false,

    options: {
      title: 'Top 5 Overdue Accounts (>90 days)',
      showFullLink: true,
      subtitle: '',
      formatColumns: ['Sold'],
      totalColumns: [
        {
          name: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart1Table: {
    name: 'custom_table_market_overview_chart1',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Off Premise', 'On Premise', '$ Sold'],

      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Off Premise', 'On Premise', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Off Premise',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        },
        {
          name: 'On Premise',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart2Table: {
    name: 'custom_table_market_overview_chart2',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Product Category', '$ Sold'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Product Category', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Product Category',
          type: 'string'
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart3Table: {
    name: 'custom_table_market_overview_chart3',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Product', '$ Sold'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Product', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Product',
          type: 'string'
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart4Table: {
    name: 'custom_table_market_overview_chart4',
    isLoading: false,
    dataSet: [],
    options: {
      columns: [
        'Year',
        'Month',
        'Total Sales',
        'Distributor',
        'Vendor',
        'Other'
      ],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: [
        'Year',
        'Month',
        'Total Sales',
        'Distributor',
        'Vendor',
        'Other'
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Distributor',
          type: 'number'
        },
        {
          name: 'Vendor',
          type: 'number'
        },
        {
          name: 'Other',
          type: 'number'
        },
        {
          name: 'Total Sales',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  }
}
