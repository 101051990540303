<template>
  <div class="animated fadeIn">
    <b-card>
      <h2>Market Overview - Distribution</h2>
      <br />
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            dataset-name="distribution-market-overview"
            :show-labels="true"
            :compact="false"
            :loaded="filteringPanel.loaded"
            :load-dictionaries="loadDictionaries"
            :districts="filteringPanel.districts"
            :filters="filteringPanel.filters"
            @dict-loaded="onFilteringPanelLoad"
            @search="getData"
            @change="onFilteringPanelChange"
            @custom-filter-selected="onCustomFilterSelected"
            @state-changed="onFilteringPanelStateChange"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col sm="4" lg="4" align="center">
          <callout-box
            ref="callout1"
            :postfix="callouts.callout1.postfix"
            :hide-sign="callouts.callout1.hideSign"
            :width="$isMobile ? '16rem' : '18rem'"
            :bg-color-class="callouts.callout1.bgColorClass"
            :loading="callouts.callout1.loading"
            :value="callouts.callout1.value"
            :header="callouts.callout1.header"
            :subheader="callouts.callout1.subheader"
            :footer="callouts.callout1.footer"
          />
        </b-col>

        <b-col sm="4" lg="4" align="center">
          <callout-box
            ref="callout2"
            :prefix="'$'"
            :loading="callouts.callout2.loading"
            :width="$isMobile ? '16rem' : '18rem'"
            :bg-color-class="callouts.callout2.bgColorClass"
            :value="callouts.callout2.value"
            :header="callouts.callout2.header"
            :subheader="callouts.callout2.subheader"
            :footer="callouts.callout2.footer"
          />
        </b-col>

        <b-col sm="4" lg="4" align="center">
          <callout-box
            ref="callout3"
            :prefix="'$'"
            :loading="callouts.callout3.loading"
            :width="$isMobile ? '16rem' : '18rem'"
            :bg-color-class="callouts.callout3.bgColorClass"
            :value="callouts.callout3.value"
            :header="callouts.callout3.header"
            :subheader="callouts.callout3.subheader"
            :footer="callouts.callout3.footer"
          />
        </b-col>
      </b-row>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <b-row>
        <b-col>
          <table-custom
            ref="table1"
            :name="`custom_table_market_overview_1`"
            :data="tables.table1.dataSet"
            :options="tables.table1.options"
            :loading="tables.table1.loading"
            @row-click="tables.table1.onRowClick"
          >
            <template #fullDataLink>
              <i
                id="full-data-link"
                class="fa fa-arrow-circle-right"
                @click="getTable1FullData"
              />
            </template>
          </table-custom>
        </b-col>
        <b-col>
          <table-custom
            ref="table2"
            :name="`custom_table_market_overview_2`"
            :data="tables.table2.dataSet"
            :options="tables.table2.options"
            :loading="tables.table2.loading"
            @row-click="tables.table2.onRowClick"
          >
            <template #fullDataLink>
              <i
                id="full-data-link"
                class="fa fa-arrow-circle-right"
                @click="getTable2FullData"
              />
            </template>
          </table-custom>
        </b-col>
        <b-col>
          <table-custom
            ref="table3"
            :name="`custom_table_market_overview_3`"
            :data="tables.table3.dataSet"
            :options="tables.table3.options"
            :loading="tables.table3.loading"
            @row-click="tables.table3.onRowClick"
          >
            <template #fullDataLink>
              <i
                id="full-data-link"
                class="fa fa-arrow-circle-right"
                @click="getTable3FullData"
              />
            </template>
          </table-custom>
        </b-col>
      </b-row>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <g-chart-custom
        ref="chart5"
        title="Account conversion"
        :subtitle="charts.chart5.subtitle"
        type="ColumnChart"
        :loading="charts.chart5.loading"
        :data="charts.chart5.chartData"
        :options="charts.chart5.chartOptions"
        @chart-element-selected="drilldownChart5"
      >
        <template #legend>
          <b-popover target="chart5-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart5-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>
      </g-chart-custom>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <g-chart-custom
        ref="chart1"
        title="Monthly sales"
        :subtitle="charts.chart1.subtitle"
        :default-mode="charts.chart1.isDefaultMode"
        type="ColumnChart"
        :loading="charts.chart1.loading"
        :data="charts.chart1.chartData"
        :options="charts.chart1.chartOptions"
        @chart-element-selected="drilldownChart1"
      >
        <template #legend>
          <b-popover target="chart1-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart1-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>

        <template #chartTopBar>
          <b-row align-h="between">
            <b-col />
            <b-col class="text-right">
              <b-button
                v-show="charts.chart1.isDefaultMode"
                class="mb-2"
                variant="outline-primary"
                @click="
                  charts.chart1.isDefaultMode = !charts.chart1.isDefaultMode
                "
              >
                <font-awesome-icon icon="list" />
              </b-button>
              <b-button
                v-show="!charts.chart1.isDefaultMode"
                class="mb-2"
                variant="outline-primary"
                @click="
                  charts.chart1.isDefaultMode = !charts.chart1.isDefaultMode
                "
              >
                <font-awesome-icon icon="chart-pie" />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #table>
          <table-custom
            :name="tables.chart1Table.name"
            :loading="tables.chart1Table.isLoading"
            :data="tables.chart1Table.dataSet"
            :options="tables.chart1Table.options"
            @mounted="onChart1TableMount"
          />
        </template>
      </g-chart-custom>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <g-chart-custom
        ref="chart2"
        title="Sales by product category"
        :default-mode="charts.chart2.isDefaultMode"
        :subtitle="charts.chart2.subtitle"
        type="AreaChart"
        :loading="charts.chart2.loading"
        :data="charts.chart2.chartData"
        :options="charts.chart2.chartOptions"
        @chart-element-selected="drilldownChart2"
      >
        <template #legend>
          <b-popover target="chart2-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart2-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>

        <template #chartTopBar>
          <b-row align-h="between">
            <b-col />
            <b-col class="text-right">
              <b-button
                v-show="charts.chart2.isDefaultMode"
                class="mb-2"
                variant="outline-primary"
                @click="
                  charts.chart2.isDefaultMode = !charts.chart2.isDefaultMode
                "
              >
                <font-awesome-icon icon="list" />
              </b-button>
              <b-button
                v-show="!charts.chart2.isDefaultMode"
                class="mb-2"
                variant="outline-primary"
                @click="
                  charts.chart2.isDefaultMode = !charts.chart2.isDefaultMode
                "
              >
                <font-awesome-icon icon="chart-pie" />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #table>
          <table-custom
            :name="tables.chart2Table.name"
            :loading="tables.chart2Table.isLoading"
            :data="tables.chart2Table.dataSet"
            :options="tables.chart2Table.options"
            @mounted="onChart2TableMount"
          />
        </template>
      </g-chart-custom>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />

      <g-chart-custom
        ref="chart3"
        :default-mode="charts.chart3.isDefaultMode"
        title="Sales by product"
        :subtitle="charts.chart3.subtitle"
        type="AreaChart"
        :loading="charts.chart3.loading"
        :data="charts.chart3.chartData"
        :options="charts.chart3.chartOptions"
        @chart-element-selected="drilldownChart3"
      >
        <template #legend>
          <b-popover target="chart3-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart3-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>
        <template #filter>
          Compare to:
          <filter-panel
            ref="filterPanelSalesByProduct"
            @search-triggered="getMarketSalesByProductChartData"
            :show-territories="false"
            :show-countries="false"
            :show-states="false"
            :show-product-categories="true"
            :show-date-range="true"
            :show-labels="true"
          />
        </template>
        <template #chartTopBar>
          <b-row align-h="between">
            <b-col />
            <b-col class="text-right">
              <b-button
                v-show="charts.chart3.isDefaultMode"
                class="mb-2"
                variant="outline-primary"
                @click="
                  charts.chart3.isDefaultMode = !charts.chart3.isDefaultMode
                "
              >
                <font-awesome-icon icon="list" />
              </b-button>
              <b-button
                v-show="!charts.chart3.isDefaultMode"
                class="mb-2"
                variant="outline-primary"
                @click="
                  charts.chart3.isDefaultMode = !charts.chart3.isDefaultMode
                "
              >
                <font-awesome-icon icon="chart-pie" />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #table>
          <table-custom
            :name="tables.chart3Table.name"
            :loading="tables.chart3Table.isLoading"
            :data="tables.chart3Table.dataSet"
            :options="tables.chart3Table.options"
            @mounted="onChart3TableMount"
          />
        </template>
      </g-chart-custom>
      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />

      <g-chart-custom
        ref="chart4"
        :default-mode="charts.chart4.isDefaultMode"
        title="Historical Activity"
        :subtitle="charts.chart4.subtitle"
        type="ComboChart"
        :loading="charts.chart4.loading"
        :data="charts.chart4.chartData"
        :options="charts.chart4.chartOptions"
        @chart-element-selected="drilldownChart4"
      >
        <template #legend>
          <b-popover target="chart4-ddi" triggers="hover" placement="right">
            <span>Chart drilldown available</span>
          </b-popover>
          <i id="chart4-ddi" class="ml-2 fa fa-share fa-rotate-90" />
        </template>

        <template #chartTopBar>
          <b-row align-h="between">
            <b-col />
            <b-col class="text-right">
              <b-button
                v-show="charts.chart4.isDefaultMode"
                class="mb-2"
                variant="outline-primary"
                @click="
                  charts.chart4.isDefaultMode = !charts.chart4.isDefaultMode
                "
              >
                <font-awesome-icon icon="list" />
              </b-button>
              <b-button
                v-show="!charts.chart4.isDefaultMode"
                class="mb-2"
                variant="outline-primary"
                @click="
                  charts.chart4.isDefaultMode = !charts.chart4.isDefaultMode
                "
              >
                <font-awesome-icon icon="chart-pie" />
              </b-button>
            </b-col>
          </b-row>
        </template>
        <template #table>
          <table-custom
            :name="tables.chart4Table.name"
            :loading="tables.chart4Table.isLoading"
            :data="tables.chart4Table.dataSet"
            :options="tables.chart4Table.options"
            @mounted="onChart4TableMount"
          />
        </template>
      </g-chart-custom>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import GChartCustom from '@/components/GChartCustom'
import CalloutBox from '@/components/CalloutBox'

import TableCustom from '@/components/TableCustom'
//import TableCustom from '@/components/TableCustomOld'

import FilteringPanel from '@/components/FilteringPanel'
import fp from './filters'
import tables from './tables'
import callouts from './callouts'
import charts from './charts'

import FilterPanel from '@/components/FilterPanelSimple'
import { options } from 'less'
///projects/Koval/koval-crm/client/src/views/Warehouse/NationalOverview/MarketModal.vue

export default {
  name: 'Overview',
  props: {
    country: {
      type: String,
      default: ''
    },
    market: {
      type: String,
      default: ''
    },
    autoload: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FilterPanel,
    FilteringPanel,
    TableCustom,
    GChartCustom,
    CalloutBox
  },
  data: function () {
    return {
      isLoading: false,
      filteringPanel: fp.filteringPanel,
      tables: tables,
      callouts: callouts,
      charts: charts
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async loadDictionaries () {
      const territories = async () => {
        let response = await this.$api.get('dictionaries/territories')

        this.filteringPanel.filters.find(
          f => f.name === 'territory'
        ).options = response.map(u => ({
          id: u.ID,
          label: u.Name
        }))
      }

      const districts = async () => {
        let response = await this.$api.post('dictionaries/countries/mapped', {
          ignore_restrictions: false
        })

        this.filteringPanel.districts = response

        this.filteringPanel.filters.find(
          f => f.name === 'country'
        ).options = this.$helpers.getDistinctArray(
          response,
          'country',
          'country',
          'id',
          'label'
        )
      }
      /*
      this.filteringPanel.filters.period2 = {
        name: 'period2',
        label: 'Compare to',
        type: 'daterange',
        value: {
          startDate: moment(this.filteringPanel.filters.period.startDate)
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          endDate: moment(this.filteringPanel.filters.period.endDate)
            .subtract(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD')
        }
      }
      */
      Promise.all([territories(), districts()]).then(
        () => (this.filteringPanel.loaded = true)
      )
    },
    updateDateRanges (payload, controlName) {
      let filter = this.$refs.filteringPanel.selected

      console.log('updateDateRanges.payload', payload)
      console.log('updateDateRanges.filter.period', filter.period)

      //if user values are not defined then set date range to 1 year prior period selected at the top
      //https://gitlab.com/koval-projects/koval-crm/-/issues/211#note_356827025

      const periodLength = moment(filter.period.endDate).diff(
        filter.period.startDate,
        'days'
      )

      const comparisonPeriod = {
        startDate: moment(filter.period.startDate)
          .subtract(periodLength + 1, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment(filter.period.startDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      }
      console.log('updateDateRanges.comparisonPeriod', comparisonPeriod)

      //filter.period2.startDate = comparisonPeriod.startDate
      //filter.period2.endDate = comparisonPeriod.endDate
      //set default period if storage was empty

      //if changed main period then update comparison period. Otherwise keep it as is
      if (controlName == 'period') {
        this.$refs.filteringPanel.$refs['period2'][0].setValue(
          comparisonPeriod,
          {
            triggerChanged: false
          }
        )
      }
    },
    onCustomFilterSelected () {
      this.getData()
    },

    onFilteringPanelLoad () {
      this.getData()
    },
    onFilteringPanelChange (payload, controlName) {
      this.updateDateRanges(payload, controlName)
      this.filterData(payload)
    },
    onFilteringPanelStateChange () {
      //this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder.visible;
    },
    filterData (e) {
      console.log('filterData', e)
      this.filteringPanel.selected = e
      //let self = this;
      //this.filteringPanel.selected = e;
    },
    getData () {
      let combinedFilter = this.getCombinedFilter()

      this.drawCallouts(combinedFilter)

      const onRowClick = function (event) {
        /*
        this.$store.dispatch(
          "filterPanel.selected.accountname",
          event.row["Account Name"]
        );
        */

        this.$form.prepareDrillDownFilters(
          this.$refs.filteringPanel,
          event.row['Account Name']
        )

        this.$router.push({ name: 'Account overview' })
      }
      this.tables.table1.onRowClick = onRowClick.bind(this)
      this.tables.table2.onRowClick = onRowClick.bind(this)
      this.tables.table3.onRowClick = onRowClick.bind(this)

      this.drawTable1(combinedFilter)
      this.drawTable2(combinedFilter)
      this.drawTable3(combinedFilter)

      this.drawChart5(combinedFilter)
      this.drawChart1(combinedFilter)
      this.drawChart2(combinedFilter)
      this.drawChart3(combinedFilter)
      this.drawChart4(combinedFilter)
    },
    drawCallouts: async function (combinedFilter) {
      this.callouts.callout1.loading = true

      let responseTotalSales = await this.$api.data.getMarketChangeInTotalSales(
        combinedFilter
      )

      this.callouts.callout1.loading = false

      let result = this.drawTotalSalesCallout(responseTotalSales)

      if (!result) return //if no sales then return

      this.callouts.callout2.loading = this.callouts.callout3.loading = true
      let responseBestWorst = await this.$api.data.getMarketBestWorstProducts(
        combinedFilter
      )
      this.callouts.callout2.loading = this.callouts.callout3.loading = false
      this.drawBestWorstCallout(responseBestWorst)
    },
    drawTotalSalesCallout: function (response) {
      if (response.length > 0) {
        let res = response[0]

        let period =
          moment(res.p2_period_start).format('MMM, YY') ===
          moment(res.p2_period_end).format('MMM, YY')
            ? moment(res.p2_period_start).format('MMM, YY')
            : `${moment(res.p2_period_start).format('MMM, YY')} - ${moment(
                res.p2_period_end
              ).format('MMM, YY')}`
        this.callouts.callout1.footer = `compared to ${period}`
        this.callouts.callout1.header = 'Sales growth:'
        this.callouts.callout1.postfix = '%'
        this.callouts.callout1.value = parseFloat(res.delta).toFixed(0)

        //1) If there are only sales for the selection period (so there is in infinite growth over the $0 sales in the reference period),
        //then we show the difference in absolute dollars. "+$[total selection sales] compared to [reference period]"
        if (parseFloat(res.p1total) > 0 && parseFloat(res.p2total) === 0) {
          let delta = (
            parseFloat(res.p1total) - parseFloat(res.p2total)
          ).toFixed(0)
          this.callouts.callout1.value = delta
          //this.callouts.callout1.hideSign = true
          this.callouts.callout1.postfix = '$'
          this.callouts.callout1.header = 'Sales change:'
          //this.callouts.callout1.bgColorClass = 'bg-secondary'
        }
        //2) If there are only sales for the reference period (so there were zero sales in the selection period, a -100% decrease compared to the reference period),
        //then again show the difference in absolute dollars as -$[total reference sales] compared to [reference period]
        if (parseFloat(res.p2total) > 0 && parseFloat(res.p1total) === 0) {
          let delta = (
            parseFloat(res.p1total) - parseFloat(res.p2total)
          ).toFixed(0)
          this.callouts.callout1.value = delta
          //this.callouts.callout1.hideSign = true
          this.callouts.callout1.postfix = '$'
          this.callouts.callout1.header = 'Sales change:'
          //this.callouts.callout1.bgColorClass = 'bg-secondary'
        }

        //3) If no sales during both selection and reference period, color box grey and show "No recorded sales [beginning of reference period] - [end of selection period]".
        //   Color product boxes grey as well and show same message
        if (parseFloat(res.p1total) === 0 && parseFloat(res.p2total) === 0) {
          //this.callouts.callout1.bgColorClass = 'bg-secondary'
          this.callouts.callout1.value = this.callouts.callout2.value = this.callouts.callout3.value =
            ''
          this.callouts.callout1.header = this.callouts.callout2.header = this.callouts.callout3.header =
            'No recorded sales'
          period = `${moment(res.p2_period_start).format('MMM, YY')} - ${moment(
            res.p1_period_end
          ).format('MMM, YY')}`
          this.callouts.callout1.footer = this.callouts.callout2.footer = this.callouts.callout3.footer = `${period}`

          return false
        }
      } else {
        this.callouts.callout1.value = ''
      }

      return true
    },
    drawBestWorstCallout: function (response) {
      if (response.length > 0) {
        const max = response.reduce(function (prev, current) {
          return parseFloat(prev.dif) > parseFloat(current.dif) ? prev : current
        })

        const min = response.reduce(function (prev, current) {
          return parseFloat(prev.dif) < parseFloat(current.dif) ? prev : current
        })

        let period =
          moment(max.p2_period_start).format('MMM, YY') ===
          moment(max.p2_period_end).format('MMM, YY')
            ? moment(max.p2_period_start).format('MMM, YY')
            : `${moment(max.p2_period_start).format('MMM, YY')} - ${moment(
                max.p2_period_end
              ).format('MMM, YY')}`

        this.callouts.callout2.value = max.dif
        this.callouts.callout2.header = 'Best product sales growth:'
        this.callouts.callout2.subheader = `${max.productname}`
        this.callouts.callout2.footer = `compared to ${period}`

        this.callouts.callout3.value = min.dif
        this.callouts.callout3.header = 'Worst product sales growth:'
        this.callouts.callout3.subheader = `${min.productname}`
        this.callouts.callout3.footer = `compared to ${period}`

        //If only one product sold (and thus had both best/worst growth),
        //grey out worst box if positive growth, or best box if negative growth
        if (response.length === 1) {
          let res = response[0]

          if (parseFloat(res.dif) > 0) {
            //this.callouts.callout3.bgColorClass = 'bg-secondary'
            this.callouts.callout3.header = 'No recorded sales'
            this.callouts.callout3.subheader = ''
            this.callouts.callout3.value = ''
            period = `${moment(res.p2_period_start).format(
              'MMM, YY'
            )} - ${moment(res.p1_period_end).format('MMM, YY')}`
            this.callouts.callout3.footer = `${period}`
          }

          if (parseFloat(res.dif) < 0) {
            // this.callouts.callout2.bgColorClass = 'bg-secondary'
            this.callouts.callout2.header = 'No recorded sales'
            this.callouts.callout2.subheader = ''
            this.callouts.callout2.value = ''
            period = `${moment(res.p2_period_start).format(
              'MMM, YY'
            )} - ${moment(res.p1_period_end).format('MMM, YY')}`
            this.callouts.callout2.footer = `${period}`
          }
        }
      } else {
        this.callouts.callout2.value = ''
        this.callouts.callout2.header = ''
        this.callouts.callout2.footer = ''

        this.callouts.callout3.value = ''
        this.callouts.callout3.header = ''
        this.callouts.callout3.footer = ''
      }
    },
    drawTable1: async function (combinedFilter) {
      this.tables.table1.loading = true

      let response = await this.$api.data['Market.TopOnPremise'](combinedFilter)

      this.tables.table1.loading = false

      this.tables.table1.dataSet = response.slice(0, 5)
    },
    getCombinedFilter: function () {
      let fp = this.$refs.filteringPanel
      //this.$refs.filterPanelSalesByProduct.loading = true;

      let f1 = this.$refs.filteringPanel.selected

      let f2 = this.$refs.filterPanelSalesByProduct.selected
      //combinedFilter.productcategory = filter2.productcategory;

      let f = {
        ...f1
      }

      let combinedFilter = {
        territories: f.territory ? f.territory : [],
        countries: f.country ? f.country.map(i => i.label) : [],
        states: f.state ? f.state.map(i => i.label) : [],
        cities: f.city ? f.city.map(i => i.label) : [],
        period: f.period,
        period2: f.period2,
        productcategory: f2.productcategory
      }

      combinedFilter = JSON.parse(JSON.stringify(combinedFilter))

      if (fp.panelState.isCustomFiltersBar) {
        combinedFilter.expression = JSON.stringify(
          fp.prepareExpressions(f['custom-filter'])
        )
      }
      return combinedFilter
    },
    getTable1FullData: async function () {
      let combinedFilter = this.getCombinedFilter()
      //let filter = this.$refs.filterPanel.selected;

      const payload = {
        filter: combinedFilter,
        parent: this.$route.name,
        description: 'Top On-premise Accounts',
        method: 'Market.TopOnPremise',
        formatColumns: []
      }

      this.$store.dispatch('drilldown.save', payload)

      //open new page
      this.$router.push({ name: 'Chart drilldown' })
    },
    drawTable2: async function (combinedFilter) {
      this.tables.table2.loading = true

      let response = await this.$api.data['Market.TopOffPremise'](
        combinedFilter
      )

      this.tables.table2.loading = false

      this.tables.table2.dataSet = response.slice(0, 5)
    },
    getTable2FullData: async function () {
      let combinedFilter = this.getCombinedFilter()
      //let filter = this.$refs.filterPanel.selected;

      this.$store.dispatch('drilldown.save', {
        filter: combinedFilter,
        parent: this.$route.name,
        description: 'Top Off-premise Accounts',
        method: 'Market.TopOffPremise',
        formatColumns: []
      })

      //open new page
      this.$router.push({ name: 'Chart drilldown' })
    },
    drawTable3: async function (combinedFilter) {
      this.tables.table3.loading = true

      let response = await this.$api.data['Market.OverdueAccounts'](
        combinedFilter
      )

      this.tables.table3.loading = false

      this.tables.table3.dataSet = response.slice(0, 5)
    },
    getTable3FullData: async function () {
      let combinedFilter = this.getCombinedFilter()
      //let filter = this.$refs.filterPanel.selected;

      this.$store.dispatch('drilldown.save', {
        filter: combinedFilter,
        parent: this.$route.name,
        description: 'Overdue Accounts',
        method: 'Market.OverdueAccounts',
        formatColumns: []
      })

      //open new page
      this.$router.push({ name: 'Chart drilldown' })
    },
    drilldownChart5: function (e) {
      //let filter = this.$refs.filterPanel.selected;

      let combinedFilter = this.getCombinedFilter()

      //get selected chart elements
      combinedFilter.category = e.data[e.selection.row + 1][0]

      //save data to storage
      let description = `${this.$refs.chart5.title} [Category: ${combinedFilter.category}]`
      this.$store.dispatch('drilldown.save', {
        filter: combinedFilter,
        parent: this.$route.name,
        description: description,
        method: 'Market.AccountConversionDrilldown'
      })

      //open new page
      this.$router.push({ name: 'Chart drilldown' })
    },
    drawChart5: async function (filter) {
      this.charts.chart5.loading = true

      this.charts.chart5.subtitle = `Vendor account count by invoice activity for the period [${moment(
        filter.period.startDate
      ).format('YYYY-MM-DD')} - ${moment(filter.period.endDate).format(
        'YYYY-MM-DD'
      )}]`

      let response = await this.$api.data['Market.AccountConversion'](filter)

      this.charts.chart5.loading = false

      let data = [
        [
          'Account Category',
          '',
          {
            role: 'style'
          },
          'Active',
          {
            role: 'style'
          }
        ]
      ]

      response.forEach(item => {
        data.push([
          item.accountcategory,
          parseFloat(item.hidden || 0),
          'opacity: 0.0',
          parseFloat(item.count || 0),
          item.style
        ])
      })

      this.charts.chart5.chartData = data
    },
    drawChart1: function (combinedFilter) {
      this.charts.chart1.loading = true

      let topFilter = this.$refs.filteringPanel.selected

      this.charts.chart1.subtitle = `Total $USD spend in the period [${moment(
        topFilter.period.startDate
      ).format('YYYY-MM-DD')} - ${moment(topFilter.period.endDate).format(
        'YYYY-MM-DD'
      )}] and equivalent previous`

      this.$api.data.getMarketSalesActivity(combinedFilter).then(response => {
        this.charts.chart1.loading = false

        if (response.length === 0) return

        this.charts.chart1.rawData = response

        let headers = [
          ['Year', 'Total Sales', 'Off-premise Sales', 'On-premise Sales']
        ]
        let data = [
          ...response.map(item => {
            return [
              new Date(moment(item.date).format('MM/DD/YY')),
              parseFloat(item.total || 0),
              parseFloat(item.totaloffpremise || 0),
              parseFloat(item.totalonpremise || 0)
            ]
          })
        ]

        this.charts.chart1.chartData = headers.concat(data)
      })
    },
    drilldownChart1: function (e) {
      //let filter = this.$refs.filteringPanel.selected;
      let filter = this.getCombinedFilter()

      //get selected chart elements
      let date = e.data[e.selection.row + 1][0]

      filter.period.startDate = date
      filter.period.endDate = moment(date).endOf('month')

      //get selected chart elements
      filter.category = e.data[e.selection.row + 1][0]

      //SalesFullReport uses multiselected filters
      //filter.countries = filter.country.name ? [filter.country.name] : [];
      //filter.states = filter.state ? [filter.state] : [];

      let description = `${this.$refs.chart1.title} [Period: ${moment(
        filter.period.startDate
      ).format('DD MMM, YY')} - ${moment(filter.period.endDate).format(
        'DD MMM, YY'
      )}; Market: ${
        filter.states.lenght > 0 ? filter.states.map(s => s.label) : 'All'
      }]`

      this.$store.dispatch('drilldown.save', {
        filter: filter,
        parent: this.$route.name,
        description: description,
        method: 'Sales.InvoiceData',
        formatColumns: ['Spent']
      })

      //open new page
      this.$router.push({ name: 'Chart drilldown' })
    },
    drawChart2: function (combinedFilter) {
      this.charts.chart2.loading = true

      let topFilter = this.$refs.filteringPanel.selected

      this.charts.chart2.subtitle = `Percent of total $USD spend in the period [${moment(
        topFilter.period.startDate
      ).format('YYYY-MM-DD')} - ${moment(topFilter.period.endDate).format(
        'YYYY-MM-DD'
      )}] and equivalent previous`

      this.$api.data.getMarketProductCategory(combinedFilter).then(response => {
        this.charts.chart2.loading = false

        if (response.length === 0) return

        this.charts.chart2.rawData = response
        //chart 2

        let data = [
          ...response.map(item => {
            //return [item.date, parseFloat(item.percent || 0), item.productcategory]
            return [
              new Date(moment(item.date).format('MM/DD/YY')),
              parseFloat(item.total || 0),
              item.productcategory
            ]
          })
        ]

        let pivot_data = this.$helpers.getPivotArray(data, 0, 2, 1, false) //sort by total sum

        this.charts.chart2.chartData = pivot_data
      })
    },
    drilldownChart2: function (e) {
      //let filter = this.$refs.filterPanel.selected;
      let filter = this.getCombinedFilter()

      //get selected chart elements
      filter.productcategory = e.data[0][e.selection.column]

      let date = e.data[e.selection.row + 1][0]
      filter.period.startDate = date
      filter.period.endDate = moment(date).endOf('month')

      //SalesFullReport uses multiselected filters
      //filter.countries = filter.country.name ? [filter.country.name] : [];
      //filter.states = filter.state ? [filter.state] : [];

      filter.productcategories = filter.productcategory
        ? [filter.productcategory]
        : []

      let description = `${this.$refs.chart2.title} [Period: ${moment(
        filter.period.startDate
      ).format('DD MMM, YY')} - ${moment(filter.period.endDate).format(
        'DD MMM, YY'
      )}; Market: ${filter.state ? filter.state : 'All'}; Category: ${
        filter.productcategory ? filter.productcategory : 'All'
      }]`

      this.$store.dispatch('drilldown.save', {
        filter: filter,
        parent: this.$route.name,
        description: description,
        method: 'Sales.InvoiceData'
      })

      //open new page
      this.$router.push({ name: 'Chart drilldown' })
    },
    drawChart3: function (combinedFilter) {
      this.charts.chart3.loading = true

      let topFilter = this.$refs.filteringPanel.selected
      this.charts.chart3.subtitle = `Percent of total $USD spend in the period [${moment(
        topFilter.period.startDate
      ).format('YYYY-MM-DD')} - ${moment(topFilter.period.endDate).format(
        'YYYY-MM-DD'
      )}] and equivalent previous`

      this.$api.data.getMarketSalesByProduct(combinedFilter).then(response => {
        this.charts.chart3.loading = false

        if (response.length === 0) return

        this.charts.chart3.rawData = response

        //this.$refs.filterPanel.loading = false;
        if (this.$refs.filterPanelSalesByProduct)
          this.$refs.filterPanelSalesByProduct.loading = false

        //chart 3

        let data = [
          ...response.map(item => {
            return [
              new Date(moment(item.date).format('MM/DD/YY')),
              parseFloat(item.total || 0),
              item.productname
            ]
          })
        ]

        let pivot_data = this.$helpers.getPivotArray(data, 0, 2, 1, false) //sort by total sum

        this.charts.chart3.chartData = pivot_data
      })
    },
    drilldownChart3: function (e) {
      //let filter = this.$refs.filterPanel.selected;

      let filter = this.getCombinedFilter()

      //get selected chart elements
      filter.product = e.data[0][e.selection.column]

      let date = e.data[e.selection.row + 1][0]
      filter.period.startDate = date
      filter.period.endDate = moment(date).endOf('month')

      //SalesFullReport uses multiselected filters
      //filter.countries = filter.country.name ? [filter.country.name] : [];
      //filter.states = filter.state ? [filter.state] : [];
      filter.products = filter.product ? [filter.product] : []

      let description = `${this.$refs.chart3.title} [Period: ${moment(
        filter.period.startDate
      ).format('DD MMM, YY')} - ${moment(filter.period.endDate).format(
        'DD MMM, YY'
      )}; Market: ${filter.state ? filter.state : 'All'}; Product: ${
        filter.product ? filter.product : 'All'
      }]`

      this.$store.dispatch('drilldown.save', {
        filter: filter,
        parent: this.$route.name,
        description: description,
        method: 'Sales.InvoiceData',
        formatColumns: ['Spent']
      })

      //open new page
      this.$router.push({ name: 'Chart drilldown' })
    },
    drawChart4: function (combinedFilter) {
      this.charts.chart4.loading = true

      let topFilter = this.$refs.filteringPanel.selected
      this.charts.chart4.subtitle = `Total touchpoint count, total $USD spend in the period [${moment(
        topFilter.period.startDate
      ).format('YYYY-MM-DD')} - ${moment(topFilter.period.endDate).format(
        'YYYY-MM-DD'
      )}] and equivalent previous`

      //the same request used for chart 1, it is the field for optimization
      this.$api.data.getMarketSalesActivity(combinedFilter).then(response => {
        this.charts.chart4.loading = false

        if (response.length === 0) return

        this.charts.chart4.rawData = response

        //chart 4
        let headers = [
          ['Year', 'Total Sales', 'Distributor', 'Vendor', 'Other']
        ]

        let data = [
          ...response.map(item => {
            return [
              new Date(moment(item.date).format('MM/DD/YY')),
              parseFloat(item.total || 0),
              parseFloat(item.distributor_aty || 0),
              parseFloat(item.account_aty || 0),
              parseFloat(item.other_aty || 0)
            ]
          })
        ]

        this.charts.chart4.chartData = headers.concat(data)
      })
    },
    drilldownChart4: function (e) {
      //let filter = this.$refs.filterPanel.selected;
      let filter = this.getCombinedFilter()

      //get selected chart elements
      let date = e.data[e.selection.row + 1][0]
      filter.category = e.data[0][e.selection.column]

      filter.period.startDate = date
      filter.period.endDate = moment(date).endOf('month')

      //save data to storage
      let description = `${this.$refs.chart4.title} [Period: ${moment(
        filter.period.startDate
      ).format('DD MMM, YY')} - ${moment(filter.period.endDate).format(
        'DD MMM, YY'
      )}, Category: ${filter.category}];`

      this.$store.dispatch('drilldown.save', {
        filter: filter,
        parent: this.$route.name,
        description: description,
        method: 'Market.HistoricalActivityDrilldown'
      })

      //open new page
      this.$router.push({ name: 'Chart drilldown' })
    },
    getMarketSalesByProductChartData: function () {
      //this.$refs.topProgress.start();
      this.$refs.filterPanelSalesByProduct.loading = true

      let filter1 = this.$refs.filterPanel.selected
      let filter2 = this.$refs.filterPanelSalesByProduct.selected

      let combinedFilter = {
        ...filter1
      }
      combinedFilter.productcategory = filter2.productcategory

      this.charts.chart3.loading = true

      this.$api.data.getMarketSalesByProduct(combinedFilter).then(response => {
        this.charts.chart3.loading = false
        //this.$refs.topProgress.done();
        this.$refs.filterPanelSalesByProduct.loading = false
        //chart 3

        let data = [
          ...response.map(item => {
            return [
              new Date(moment(item.date).format('MM/DD/YY')),
              parseFloat(item.total || 0),
              item.productname
            ]
          })
        ]

        let pivot_data = this.$helpers.getPivotArray(data, 0, 2, 1, false) //sort by total sum

        this.charts.chart3.chartData = pivot_data
      })
    },
    onChart1TableMount () {
      console.log('onChart1TableMount', this.charts.chart1.rawData)

      tables.chart1Table.dataSet = this.charts.chart1.rawData.map(i => ({
        Year: moment(i.date).format('YYYY'),
        Month: moment(i.date).format('MMMM'),
        'Off Premise': i.totaloffpremise,
        'On Premise': i.totalonpremise,
        '$ Sold': i.total
      }))
    },
    onChart2TableMount () {
      tables.chart2Table.dataSet = this.charts.chart2.rawData.map(i => ({
        Year: moment(i.date).format('YYYY'),
        Month: moment(i.date).format('MMMM'),
        'Product Category': i.productcategory,
        '$ Sold': i.total
      }))
    },
    onChart3TableMount () {
      //console.log('onChart3TableMount', this.charts.chart3.rawData)

      tables.chart3Table.dataSet = this.charts.chart3.rawData.map(i => ({
        Year: moment(i.date).format('YYYY'),
        Month: moment(i.date).format('MMMM'),
        Product: i.productname,
        '$ Sold': i.total
      }))
    },
    onChart4TableMount () {
      console.log('onChart4TableMount', this.charts.chart4.rawData)
      tables.chart4Table.dataSet = this.charts.chart4.rawData.map(i => ({
        Year: moment(i.date).format('YYYY'),
        Month: moment(i.date).format('MMMM'),
        Distributor: i.distributor_aty,
        Vendor: i.account_aty,
        Other: i.other_aty,
        'Total Sales': i.total
      }))
    }
  },
  watch: {
    'filteringPanel.selected.country': function (newVal) {
      if (!newVal) return

      this.filteringPanel.filters.find(
        f => f.name === 'state'
      ).options = this.$helpers.getDistinctArray(
        this.filteringPanel.districts.filter(s =>
          newVal.map(l => l.label).includes(s.country)
        ),
        'state',
        'state',
        'id',
        'label'
      )
    },
    'filteringPanel.selected.state': function (newVal) {
      if (!newVal) return

      this.filteringPanel.filters.find(
        f => f.name === 'city'
      ).options = this.$helpers.getDistinctArray(
        this.filteringPanel.districts.filter(s =>
          newVal.map(l => l.label).includes(s.state)
        ),
        'city',
        'city',
        'id',
        'label'
      )
    }
  }
}
</script>

<style></style>
