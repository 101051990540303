var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('h2', [_vm._v("Market Overview - Distribution")]), _c('br'), _c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "distribution-market-overview",
      "show-labels": true,
      "compact": false,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "dict-loaded": _vm.onFilteringPanelLoad,
      "search": _vm.getData,
      "change": _vm.onFilteringPanelChange,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4",
      "lg": "4",
      "align": "center"
    }
  }, [_c('callout-box', {
    ref: "callout1",
    attrs: {
      "postfix": _vm.callouts.callout1.postfix,
      "hide-sign": _vm.callouts.callout1.hideSign,
      "width": _vm.$isMobile ? '16rem' : '18rem',
      "bg-color-class": _vm.callouts.callout1.bgColorClass,
      "loading": _vm.callouts.callout1.loading,
      "value": _vm.callouts.callout1.value,
      "header": _vm.callouts.callout1.header,
      "subheader": _vm.callouts.callout1.subheader,
      "footer": _vm.callouts.callout1.footer
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "lg": "4",
      "align": "center"
    }
  }, [_c('callout-box', {
    ref: "callout2",
    attrs: {
      "prefix": '$',
      "loading": _vm.callouts.callout2.loading,
      "width": _vm.$isMobile ? '16rem' : '18rem',
      "bg-color-class": _vm.callouts.callout2.bgColorClass,
      "value": _vm.callouts.callout2.value,
      "header": _vm.callouts.callout2.header,
      "subheader": _vm.callouts.callout2.subheader,
      "footer": _vm.callouts.callout2.footer
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4",
      "lg": "4",
      "align": "center"
    }
  }, [_c('callout-box', {
    ref: "callout3",
    attrs: {
      "prefix": '$',
      "loading": _vm.callouts.callout3.loading,
      "width": _vm.$isMobile ? '16rem' : '18rem',
      "bg-color-class": _vm.callouts.callout3.bgColorClass,
      "value": _vm.callouts.callout3.value,
      "header": _vm.callouts.callout3.header,
      "subheader": _vm.callouts.callout3.subheader,
      "footer": _vm.callouts.callout3.footer
    }
  })], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('table-custom', {
    ref: "table1",
    attrs: {
      "name": "custom_table_market_overview_1",
      "data": _vm.tables.table1.dataSet,
      "options": _vm.tables.table1.options,
      "loading": _vm.tables.table1.loading
    },
    on: {
      "row-click": _vm.tables.table1.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "fullDataLink",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-arrow-circle-right",
          attrs: {
            "id": "full-data-link"
          },
          on: {
            "click": _vm.getTable1FullData
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', [_c('table-custom', {
    ref: "table2",
    attrs: {
      "name": "custom_table_market_overview_2",
      "data": _vm.tables.table2.dataSet,
      "options": _vm.tables.table2.options,
      "loading": _vm.tables.table2.loading
    },
    on: {
      "row-click": _vm.tables.table2.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "fullDataLink",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-arrow-circle-right",
          attrs: {
            "id": "full-data-link"
          },
          on: {
            "click": _vm.getTable2FullData
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', [_c('table-custom', {
    ref: "table3",
    attrs: {
      "name": "custom_table_market_overview_3",
      "data": _vm.tables.table3.dataSet,
      "options": _vm.tables.table3.options,
      "loading": _vm.tables.table3.loading
    },
    on: {
      "row-click": _vm.tables.table3.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "fullDataLink",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-arrow-circle-right",
          attrs: {
            "id": "full-data-link"
          },
          on: {
            "click": _vm.getTable3FullData
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('g-chart-custom', {
    ref: "chart5",
    attrs: {
      "title": "Account conversion",
      "subtitle": _vm.charts.chart5.subtitle,
      "type": "ColumnChart",
      "loading": _vm.charts.chart5.loading,
      "data": _vm.charts.chart5.chartData,
      "options": _vm.charts.chart5.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart5
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart5-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart5-ddi"
          }
        })];
      },
      proxy: true
    }])
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "title": "Monthly sales",
      "subtitle": _vm.charts.chart1.subtitle,
      "default-mode": _vm.charts.chart1.isDefaultMode,
      "type": "ColumnChart",
      "loading": _vm.charts.chart1.loading,
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart1
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart1-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart1-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1.isDefaultMode,
            expression: "charts.chart1.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isDefaultMode = !_vm.charts.chart1.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart1.isDefaultMode,
            expression: "!charts.chart1.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isDefaultMode = !_vm.charts.chart1.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.tables.chart1Table.name,
            "loading": _vm.tables.chart1Table.isLoading,
            "data": _vm.tables.chart1Table.dataSet,
            "options": _vm.tables.chart1Table.options
          },
          on: {
            "mounted": _vm.onChart1TableMount
          }
        })];
      },
      proxy: true
    }])
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('g-chart-custom', {
    ref: "chart2",
    attrs: {
      "title": "Sales by product category",
      "default-mode": _vm.charts.chart2.isDefaultMode,
      "subtitle": _vm.charts.chart2.subtitle,
      "type": "AreaChart",
      "loading": _vm.charts.chart2.loading,
      "data": _vm.charts.chart2.chartData,
      "options": _vm.charts.chart2.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart2
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart2-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart2-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2.isDefaultMode,
            expression: "charts.chart2.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isDefaultMode = !_vm.charts.chart2.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart2.isDefaultMode,
            expression: "!charts.chart2.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isDefaultMode = !_vm.charts.chart2.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.tables.chart2Table.name,
            "loading": _vm.tables.chart2Table.isLoading,
            "data": _vm.tables.chart2Table.dataSet,
            "options": _vm.tables.chart2Table.options
          },
          on: {
            "mounted": _vm.onChart2TableMount
          }
        })];
      },
      proxy: true
    }])
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('g-chart-custom', {
    ref: "chart3",
    attrs: {
      "default-mode": _vm.charts.chart3.isDefaultMode,
      "title": "Sales by product",
      "subtitle": _vm.charts.chart3.subtitle,
      "type": "AreaChart",
      "loading": _vm.charts.chart3.loading,
      "data": _vm.charts.chart3.chartData,
      "options": _vm.charts.chart3.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart3
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart3-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart3-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_vm._v(" Compare to: "), _c('filter-panel', {
          ref: "filterPanelSalesByProduct",
          attrs: {
            "show-territories": false,
            "show-countries": false,
            "show-states": false,
            "show-product-categories": true,
            "show-date-range": true,
            "show-labels": true
          },
          on: {
            "search-triggered": _vm.getMarketSalesByProductChartData
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart3.isDefaultMode,
            expression: "charts.chart3.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3.isDefaultMode = !_vm.charts.chart3.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart3.isDefaultMode,
            expression: "!charts.chart3.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3.isDefaultMode = !_vm.charts.chart3.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.tables.chart3Table.name,
            "loading": _vm.tables.chart3Table.isLoading,
            "data": _vm.tables.chart3Table.dataSet,
            "options": _vm.tables.chart3Table.options
          },
          on: {
            "mounted": _vm.onChart3TableMount
          }
        })];
      },
      proxy: true
    }])
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('g-chart-custom', {
    ref: "chart4",
    attrs: {
      "default-mode": _vm.charts.chart4.isDefaultMode,
      "title": "Historical Activity",
      "subtitle": _vm.charts.chart4.subtitle,
      "type": "ComboChart",
      "loading": _vm.charts.chart4.loading,
      "data": _vm.charts.chart4.chartData,
      "options": _vm.charts.chart4.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart4
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart4-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart4-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart4.isDefaultMode,
            expression: "charts.chart4.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4.isDefaultMode = !_vm.charts.chart4.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart4.isDefaultMode,
            expression: "!charts.chart4.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4.isDefaultMode = !_vm.charts.chart4.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.tables.chart4Table.name,
            "loading": _vm.tables.chart4Table.isLoading,
            "data": _vm.tables.chart4Table.dataSet,
            "options": _vm.tables.chart4Table.options
          },
          on: {
            "mounted": _vm.onChart4TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }